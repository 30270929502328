import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as $ from 'jquery';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { Dropdown } from '../Common';
import DialogLayout from './DialogLayout';
import imageRegisterLarge from '@/Assets/registerAll1EN.574ab5b6.svg';
import imageRegisterSmall from '@/Assets/registerAll2.d214ed67.svg';

interface ISectionProps {
  open: boolean;
  onClose(): void;
  openLoginForm(): void;
}
interface IRegisterPayload {
  name: string;
  country_id: string;
  email: string;
  password: string;
  password_confirm: string;
}

const initialPayload: IRegisterPayload = {
  name: '',
  country_id: '1',
  email: '',
  password: '',
  password_confirm: '',
};

const RegisterUserPopup: React.FC<ISectionProps> = ({
  open,
  onClose,
  openLoginForm,
}) => {
  const { t } = useTranslation('translation');
  const [data, setData] = React.useState(initialPayload);
  const [message, setMessage] = React.useState('');
  const [terms, setTerms] = React.useState<boolean>();

  const jQuerycode = () => {
    $(document).on('submit', '.signup-form', function (event) {
      var COUNTRY_CODE = {
          Albania: '1',
          Kosovo: '2',
        },
        SUBMIT_FORM_URL = {
          Albania: 'https://user.idrapoll.com/reg.php',
          Kosovo: 'https://userks.idrapoll.com/reg.php',
        },
        REGISTERED_URL = {
          Albania: 'https://user.idrapoll.com/registered.php',
          Kosovo: 'https://userks.idrapoll.com/registered.php',
        };

      event.preventDefault();
      event.stopPropagation();
      let _this = $(this);
      const fullNameExp = /^([a-zA-Z]+\s)+[a-zA-Z]+$/g;
      const is_published_page = true;

      const name = _this.find('.input-name').val();
      const country_id = _this.find('.input-country').val();
      const password = _this.find('.input-password').val();
      const password_confirm = _this.find('.input-password-confirm').val();
      // const email = _this.find('.input-email').val();
      const signUpWarning = _this.find('.warning-line');
      // const signUpSuccess = _this.find(".success-line");
      if (name && !name.toString().match(fullNameExp)) {
        const message = t('message.pleaseEnterAValidFullName');
        signUpWarning.text(message);
        _this.find('.input-name').focus();
        return;
      }

      if (data.password && data.password.toString().length < 8) {
        const message = t('message.yourPasswordMustBeAtLeast8Characters');
        signUpWarning.text(message);
        _this.find('.input-password').focus();
        setMessage(message);
        return;
      }

      if (password !== password_confirm) {
        const message = t('message.thesePasswordDontMatch');
        setMessage(message);
        signUpWarning.text(message);
        _this.find('.input-password-confirm').focus();
        return;
      }

      // const formdata = {
      //   is_published_page,
      //   name,
      //   country_id,
      //   password,
      //   password_confirm,
      //   email,
      // };
      const formdata = { ...data, is_published_page };

      //Create submit URL
      var submitURL =
        country_id === COUNTRY_CODE['Albania']
          ? SUBMIT_FORM_URL['Albania']
          : SUBMIT_FORM_URL['Kosovo'];
      var registeredURL =
        country_id === COUNTRY_CODE['Albania']
          ? REGISTERED_URL['Albania']
          : REGISTERED_URL['Kosovo'];
      // const signUpSubmit = _this.find('.sign-up-submit-button');
      // signUpSubmit.attr('disabled', true);
      $.ajax({
        type: 'POST',
        url: submitURL,
        dataType: 'json',
        data: formdata,
        success: function (data) {
          // signUpSubmit.attr('disabled', false);
          if (data['status']) {
            $('.signup-form').each(function () {
              // this.reset();
            });
            signUpWarning.text('');
            window.location.href = registeredURL;
          } else {
            signUpWarning.text('*' + data['message']);
          }
        },
        error: function (error) {
          // signUpSubmit.attr('disabled', false);
          signUpWarning.text('*Something went wrong. ' + error['statusText']);
        },
      });
    });
  };

  const _renderForm = () => (
    <Box
      component="form"
      className="sign-up signup-form"
      action="https://user.idrapoll.com/sign_up.php"
      method="POST"
      id="signUpForm1"
      onSubmit={jQuerycode}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        mx: 'auto',
        '@media screen and (min-width:1000px)': { pl: 3 },
        '@media screen and (max-width:1000px)': {},
      }}
    >
      <p className="warning-line" style={{ color: 'red' }}>
        {message}
      </p>
      <Typography
        variant="h3"
        sx={{
          fontSize: { xs: 'calc(1.3rem + .6vw)', lg: '1.75rem' },
          mb: '0.5rem',
        }}
      >
        {t('title.register')}
      </Typography>
      <Stack>
        <InputLabel>
          {t('label.fullName')} <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          id="name"
          name="name"
          className="input-name"
          placeholder={t('placeholder.fullNameRegister')}
          value={data.name}
          onChange={(e: any) => setData({ ...data, name: e.target.value })}
          size="small"
          sx={{
            mb: '3%',
            width: '100%',
          }}
          required
        />
      </Stack>
      <Stack>
        <InputLabel>
          {t('label.email')} <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          id="email"
          name="email"
          type="email"
          className="input-email"
          placeholder={t('placeholder.email')}
          value={data.email}
          onChange={(e: any) => setData({ ...data, email: e.target.value })}
          size="small"
          sx={{
            mb: '3%',
          }}
          required
        />
      </Stack>
      <Stack>
        <InputLabel>
          {t('label.password')} <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          id="password"
          name="password"
          className="input-password"
          type="password"
          placeholder={t('placeholder.password')}
          value={data.password}
          onChange={(e: any) => setData({ ...data, password: e.target.value })}
          size="small"
          sx={{
            mb: '3%',
          }}
          required
        />
      </Stack>
      <Stack>
        <InputLabel>
          {t('label.confirmPassword')} <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <TextField
          className="input-password-confirm"
          id="password_confirm"
          name="password_confirm"
          type="password"
          placeholder={t('placeholder.confirmPassword')}
          value={data.password_confirm}
          onChange={(e: any) =>
            setData({ ...data, password_confirm: e.target.value })
          }
          size="small"
          sx={{
            mb: '5%',
          }}
          required
        />
      </Stack>
      <Stack>
        <InputLabel>{t('label.country')}</InputLabel>
        <Dropdown
          id="country_id"
          name="country_id"
          className="input-country"
          sx={{ mb: 2 }}
          defaultValue="1"
          value={data.country_id}
          onChange={(e: any) =>
            setData({ ...data, country_id: e.target.value })
          }
          options={[
            { label: t('label.albania'), value: '1' },
            { label: t('label.kosovo'), value: '2' },
          ]}
          required
        />
      </Stack>
      <Stack direction="row" alignItems="center">
        <Checkbox
          id="su_input_terms_and_conditions1"
          className="checkbox"
          name="check"
          checked={terms}
          onChange={(e) => {
            setTerms(e.target.checked);
          }}
          size="small"
          required
        />
        <Typography
          sx={{
            fontFamily: 'inherit',
            fontSize: { xs: '0.75rem', sm: '1rem' },
          }}
        >
          {t('label.IAgreeToThe')}
          <Link
            className="terms-register"
            href="/policies"
            target="_blank"
            sx={{ cursor: 'pointer' }}
          >
            {t('label.TermsAndConditions')}
          </Link>
        </Typography>
      </Stack>
      <Stack direction="row" mb={3}>
        <Checkbox
          id="su_input_send_email1"
          className="checkbox"
          name="check"
          checked
          // onChange={(e) => {
          //   setNews(e.target.checked);
          // }}
          size="small"
        />
        <Typography sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}>
          {t('label.wouldYouLike')}
        </Typography>
      </Stack>
      <Button
        type="submit"
        variant="outlined"
        sx={{
          color: 'white',
          mb: '3%',
          backgroundColor: '#F26F26',
          border: '1px solid #F26F26',
          textTransform: 'capitalize',
          fontWeight: 400,
          '&.MuiButtonBase-root:hover': {
            backgroundColor: '#5C7D95',
            border: '1px solid rgba(0, 0, 0, 0.705)',
          },
        }}
      >
        {t('button.register')}
      </Button>
    </Box>
  );

  const _renderContent = () => (
    <Stack
      className="container"
      sx={{
        pb: 1,
        flexDirection: 'row',
        width: '700px',
        maxHeight: '100%',
        maxWidth: '100%',
        '@media screen and (max-width: 1000px)': {
          flexDirection: 'column',
          maxHeight: '100%',
        },
      }}
    >
      <Box sx={{ position: 'fixed', right: 0, top: 0 }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            // reset();
            onClose();
            setData(initialPayload);
          }}
        >
          <DisabledByDefaultIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        className="registerImage"
        sx={{
          width: '100%',
          '@media screen and (max-width: 1000px)': {
            boxShadow: '0px 10px 5px -5px rgb(223, 223, 223)',
          },
          '@media screen and (min-width: 1000px)': {
            width: '30%',
          },
        }}
      >
        <Box
          width="100%"
          height="100%"
          component="img"
          src={imageRegisterLarge}
          sx={{
            pr: '24px',
            '@media screen and (max-width: 1000px)': {
              display: 'none',
            },
            '@media screen and (min-width: 1000px)': {
              boxShadow: '10px 0 5px -5px rgb(223, 223, 223)',
            },
          }}
        ></Box>
        <Box
          width="100%"
          component="img"
          src={imageRegisterSmall}
          sx={{
            display: 'none',
            '@media screen and (max-width: 1000px)': {
              display: 'block',
            },
          }}
        ></Box>
      </Box>
      <Stack width="100%">
        {_renderForm()}
        <Typography textAlign="center">
          {t('label.haveAnAccount')}{' '}
          <span
            onClick={() => {
              openLoginForm();
              setData(initialPayload);
            }}
            style={{ cursor: 'pointer', color: '#F26F26' }}
          >
            {t('button.signIn')}
          </span>
        </Typography>
      </Stack>
    </Stack>
  );

  const renderMain = () => <>{_renderContent()}</>;

  return (
    <DialogLayout
      title=""
      content={renderMain()}
      open={open}
      onClose={onClose}
    />
  );
};

export default RegisterUserPopup;
