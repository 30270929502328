import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';

import { Box, Stack, Typography, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';
import {
  linkCoppyRightStyle,
  linkinfoItemStyle,
  socicalMediaItemStyle,
} from './Footer.styles';
import { getSavedLanguage } from '@/Utils/Cookie.utils';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    const currentLanguage = getSavedLanguage();
    i18next.changeLanguage(currentLanguage);
  }, []);

  return (
    <Box
      component="footer"
      className="footer"
      sx={{
        background: 'rgb(34, 32, 32)',
        color: '#ffffff',
        fontFamily: 'inherit',
      }}
    >
      <Stack
        direction="row"
        className="container"
        sx={{
          px: '5%',
          pt: '30px',
          flexDirection: { xs: 'column', md: 'row' },
          maxWidth: { xl: '80%' },
          mx: { xs: 'auto' },
        }}
      >
        <Stack
          className="footer-coppyright"
          direction="column"
          sx={{
            textAlign: 'center',
            width: '100%',
            maxWidth: { xs: '400px', md: '100%' },
            mx: 'auto',
            pb: '3%',
          }}
        >
          <Typography
            style={{
              padding: '0 12px 20px',
              textAlign: 'center',
              textAlignLast: 'center',
              fontFamily: 'inherit',
            }}
          >
            {t('footer.text')}
          </Typography>
          <Stack
            direction="row"
            sx={{
              px: 'calc(1.5rem*.5)',
              mx: 'auto',
            }}
          >
            <Link
              href="https://www.idracompany.com/files/Documents/Certifikatat_9001-2015_IDRA.pdf"
              sx={linkCoppyRightStyle}
            >
              <span style={{ fontSize: 'inherit' }}>ISO 9001</span>
              <CheckCircleIcon sx={{ fontSize: 'inherit' }} />
            </Link>
            <Link
              href="https://www.idracompany.com/files/Documents/Certifikatat_20252-2012_IDRA.pdf"
              sx={linkCoppyRightStyle}
            >
              <span style={{ fontSize: 'inherit' }}>ISO 20252</span>
              <CheckCircleIcon sx={{ fontSize: 'inherit' }} />
            </Link>
            <Link href="https://esomar.org/" sx={linkCoppyRightStyle}>
              <span style={{ fontSize: 'inherit' }}>ESOMAR</span>
              <CheckCircleIcon sx={{ fontSize: 'inherit' }} />
            </Link>
          </Stack>
        </Stack>
        <Stack
          className="footer-linkinfo"
          direction="column"
          sx={{
            textAlign: 'center',
            width: { xs: '100%', md: '160%' },
            pb: '5%',
          }}
        >
          <Stack
            // direction="column"
            justifyContent="space-evenly"
            sx={{ flexDirection: { md: 'row' } }}
          >
            <Stack justifyContent="center" direction="row">
              <Link
                onClick={() => Utils.redirect(ROUTERS.FAQ)}
                sx={linkinfoItemStyle}
              >
                <DescriptionOutlinedIcon sx={{ fontSize: '25px', mr: 1 }} />
                FAQ
              </Link>
            </Stack>
            {/* <Stack justifyContent="center" direction="row">
              <Link
                onClick={() => Utils.redirect(ROUTERS.CONTACT)}
                sx={linkinfoItemStyle}
              >
                <EmailOutlinedIcon sx={{ fontSize: '25px', mr: 1 }} />
                {t('footer.contact')}
              </Link>
            </Stack> */}
            <Stack justifyContent="center" direction="row">
              <Link
                onClick={() => Utils.redirect(ROUTERS.UNSUBSCRIBE)}
                sx={linkinfoItemStyle}
              >
                <UnsubscribeOutlinedIcon sx={{ fontSize: '25px', mr: 1 }} />
                {t('footer.unSubcribe')}
              </Link>
            </Stack>
            <Stack justifyContent="center" direction="row">
              <Link
                onClick={() => Utils.redirect(ROUTERS.POLICIES)}
                sx={linkinfoItemStyle}
              >
                <SettingsOutlinedIcon sx={{ fontSize: '25px', mr: 1 }} />
                {t('footer.termsAndConditions')}
              </Link>
            </Stack>
          </Stack>
          <Typography
            sx={{
              textAlign: 'center',
              pt: { xs: '3%', lg: '12%' },
              fontFamily: 'inherit',
            }}
          >
            {new Date().getFullYear()}{t('footer.coppyRight')}
          </Typography>
        </Stack>
        <Stack
          className="footer-socialmedia"
          direction="row"
          sx={{
            width: { xs: '100%', md: '40%' },
            justifyContent: 'center',
            pb: '3%',
          }}
        >
          <Link
            href="https://www.instagram.com/idra_company/"
            target="_blank"
            sx={{
              ...socicalMediaItemStyle,
              '&:hover': { color: '#BC2A8D' },
            }}
          >
            <InstagramIcon sx={{ fontSize: '25px' }} />
          </Link>
          <Link
            href="https://www.facebook.com/idraresearchandconsulting/"
            target="_blank"
            sx={{
              ...socicalMediaItemStyle,
              '&:hover': { color: '#4267B2' },
            }}
          >
            <FacebookIcon sx={{ fontSize: '25px' }} />
          </Link>

          <Link
            href="https://www.linkedin.com/company/idra-research-&-consulting"
            target="_blank"
            sx={{
              ...socicalMediaItemStyle,
              '&:hover': { color: '#0077B5' },
            }}
          >
            <LinkedInIcon sx={{ fontSize: '25px' }} />
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(Footer);
