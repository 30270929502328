export default {
  dashboard: 'PULT',
  profile: 'PROFILE',
  events: 'EVENTS',
  eventsList: 'EVENTS LIST',
  eventReport: 'EVENT REPORT',
  reportStatistics: 'REPORT STATISTICS',

  createUser: 'Krijo përdorues',
  email: 'Email',
  createCustomers: 'Krijoni klientë',
  inboxDetail: 'Detajet e kutisë hyrëse',

  rewards: 'Shpërblimet',
  earnRewardsForYourOpinion: 'FITO SHPËRBLIME PËR OPINIONIN TËND',
  // forClients: 'Për Klientët',
  productsAndServices: 'Produktet dhe shërbimet',
  whatIsIDRApoll: 'Çfarë është IDRApoll',
  content: 'Përmbajtja',
  statistical: 'Statistikore',
  whyIDRApoll: 'Pse IDRApoll?',
  resultsFromIDRApoll: 'Rezultatet nga IDRApoll',
  createResults: 'Krijo Rezultate',
  updateResults: 'Përditëso Rezultatet',
  customers: 'Klientët',
  unsubscribeInbox: 'Çregjistrohu në Inbox',
  marketResearch: 'HULUMTIMI I TREGUT',
  opinionResearch: 'HULUMTIMI I OPINIONIT',
  trustedBy: 'Klientët',
  contact: 'Kontakt',
  results: 'Rezultate',
  register: 'Regjistrohu',
  FAQ: 'Pyetjet më të shpeshta',
  termsAndConditions: 'Termat dhe kushtet e përdorimit',
  upload: 'Ngarkoni',
  unsubscribe: 'Unsubscribe',
  updateCustomer: 'Update Customer',
  heroTitle: 'Fito shpërblime për',
  subHeroTitle: 'opinionin tënd',
  contactDetail: 'Detajet e Kontaktit',
  systemUser: 'Përdoruesi i Sistemit',
  areYouSure: 'A je i sigurt?',
  lastNews: 'Më të fundit',
};
