import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async'; 
import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { ROUTERS } from '@/Constants';

import { IResults } from '@/Interfaces/Results.interface';
import ResultsAction from '@/Actions/Results.action';
import { Box, Link, Stack, Typography } from '@mui/material';
import { containerStyle, sectionTitleStyle } from './home.style';
import { Loading } from '@/Components/Common';

const { fetchResultsHomepage, clearResults } = ResultsAction;

const ResultsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const payload: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'results')
  );
  const isLoading: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'isFetchLoading')
  );
  const currentLanguage = Utils.getSavedLanguage();
  const [lengthResults, setLengthResults] = React.useState(1);

  React.useEffect(() => {
    dispatch(
      fetchResultsHomepage({
        languageCode: currentLanguage,
        isLandingPage: true,
        isPublished: true,
      })
    );
    return () => {
      dispatch(clearResults());
    };
  }, [currentLanguage]);

  React.useEffect(() => {
    if (!_.isEmpty(payload)) setLengthResults(payload.data.length);
  }, [payload]);

  const _renderContentResults = () => {
    return (
      <>
        <Stack
          direction="row"
          onClick={() => Utils.redirect(ROUTERS.ALL_RESULTS)}
          sx={{
            position: 'relative',
            my: '1em',
            cursor: 'pointer',
            alignItems: 'center',
            marginLeft: 'auto',
            '&:after': {
              display: 'inline-block',
              content: '"→"',
              transition: 'transform 0.3s ease-out',
              fontSize: '30px',
              pl: '8px',
            },
            '&:hover:after': {
              transform: 'translateX(4px)',
            },
          }}
        >
          <Link
            underline="none"
            sx={{
              color: 'black',
              fontSize: '20px',
              transition: '0.5s',
            }}
          >
            {t('button.viewAll')}
          </Link>
        </Stack>
        <Box
          className="Slider"
          sx={{
            div: {
              textAlign: 'center',
            },
            button: {
              color: 'black',
              '::before': {
                color: 'black',
                fontSize: '30px',
              },
            },
          }}
        >
          {_.isEmpty(payload.data) ? (
            <Typography sx={{ fontSize: '1.25rem' }}>
              {t('message.thereIsNoResultsToDisplay')}
            </Typography>
          ) : (
            <Slider
              dots={true}
              infinite={true}
              slidesToShow={lengthResults > 3 ? 4 : 1}
              slidesToScroll={lengthResults > 3 ? 4 : 1}
              autoplay={true}
              speed={1000}
              autoplaySpeed={5000}
              cssEase="linear"
              responsive={[
                {
                  breakpoint: 1380,
                  settings: {
                    slidesToShow: lengthResults > 2 ? 3 : 1,
                    slidesToScroll: lengthResults > 2 ? 3 : 1,
                  },
                },
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: lengthResults > 1 ? 2 : 1,
                    slidesToScroll: lengthResults > 1 ? 2 : 1,
                    arrows: false,
                  },
                },
                {
                  breakpoint: 723,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                  },
                },
              ]}
            >
              {_renderResultItems()}
            </Slider>
          )}
        </Box>
      </>
    );
  };

  const _renderResultItems = () => {
    const render =
      !_.isEmpty(payload) &&
      payload.data?.map((items, index) => (
        <Box
          key={index}
          className="resultItem"
          flex={1}
          sx={{
            position: 'relative',
            // width: '100%',
            maxWidth: { xl: '330px', lg: '300px', sm: '300px', xs: '330px' },
            m: { xs: 'auto', sm: '10px' },
            cursor: 'pointer',
            '&:hover': {
              '& .resultItem-text': {
                zIndex: -2,
              },
              '& .resultItem-image': {
                opacity: 0.1,
              },
            },
          }}
          onClick={() => {
            Utils.redirect(ROUTERS.RESULTS_DETAIL, { id: items.id });
          }}
        >
          <Box
            className="resultItem-image"
            component="img"
            src={items.imageUrl}
            sx={{
              objectFit: 'cover',
              height: { xs: '330px' },
              width: '100%',
              zIndex: '-3',
              transition: '0.5s',
            }}
          />
          <Box
            className="resultItem-hoverText"
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: '0',
              backgroundColor: '#4d4646',
              color: 'white',
              textAlign: 'center',
              height: '100%',
              border: 'solid transparent',
              zIndex: '-1',
              px: '10px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontfamily: '"Open Sans"',
                fontSize: '20px',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 6,
              }}
            >
              {items.imageDescription}
            </Typography>
          </Box>
          <Box
            className="resultItem-text"
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, .7)',
              // '&:hover': {
              // zIndex: '-2',
              // },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: 'white',
                px: '2%',
                mx: 'auto',
                fontSize: '19px',
                fontFamily: '"Gill Sans Extrabold", sans-serif',
                textAlign: 'center',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
              }}
            >
              {items.title}
            </Typography>
          </Box>
        </Box>
      ));
    return render;
  };

  return (
    <>
      <Helmet>
        <title>Rezultatet e Sondazheve - IDRApoll</title>
        <meta
          name="description"
          content="Shikoni rezultatet më të fundit të sondazheve nga IDRApoll. Rezultate të sakta dhe analitike për Shqipëri dhe Kosovë."
        />
        <meta
          name="keywords"
          content="rezultate sondazhesh, anketa online, IDRApoll, Shqipëri, Kosovë"
        />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="Rezultatet e Sondazheve - IDRApoll" />
        <meta
          property="og:description"
          content="Shikoni rezultatet më të fundit të sondazheve nga IDRApoll. Rezultate të sakta dhe analitike për Shqipëri dhe Kosovë."
        />
        <meta property="og:image" content="/src/Assets/results-banner.png" />
        <meta property="og:url" content="https://www.idrapoll.com/all" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Rezultatet e Sondazheve - IDRApoll" />
        <meta
          name="twitter:description"
          content="Shikoni rezultatet më të fundit të sondazheve nga IDRApoll. Rezultate të sakta dhe analitike për Shqipëri dhe Kosovë."
        />
        <meta name="twitter:image" content="/src/Assets/results-banner.png" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.idrapoll.com/all" />
      </Helmet>

      <Box id="results" component="section">
        <Box
          className="section-container"
          sx={{ ...containerStyle, maxWidth: '90%', mx: 'auto' }}
        >
          <Stack>
            <Typography className="title" variant="h4" sx={sectionTitleStyle}>
              {t('title.resultsFromIDRApoll')}
            </Typography>
            {isLoading ? <Loading /> : _renderContentResults()}
          </Stack>
        </Box>
      </Box>
    </>
  );

};

export default ResultsSection;
