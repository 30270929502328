import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DefaultLayout from '@/Components/DefaultLayout';
import { InboxActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import unsubscribeImage from '@/Assets/unsubscribee.jpg';
import {
  Box,
  FormControl,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { heroTitleStyle } from '../Policies/policies.style';
import { LoadingButton } from '@mui/lab';
import { Dropdown } from '@/Components/Common';

const { unsubscribe } = InboxActions;
const Unsubscribe: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const [selectedValue, setSelectedValue] = React.useState('');
  const [country, setCountry] = React.useState('Albania');
  const [selectOther, setSelectOther] = React.useState('');
  const [email, setEmail] = React.useState('');
  const isLoading = useSelector((state: RootState) =>
    _.get(state.INBOX, 'isActionLoading')
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'other') {
      setSelectedValue('');
      setSelectOther('other');
    } else {
      setSelectedValue(event.target.value);
      setSelectOther('');
    }
  };

  const RadioButton = () => {
    return (
      <RadioGroup
        aria-label="reason"
        name="reason"
        value={selectedValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value={t('label.unsubscribeOption1')}
          control={<Radio />}
          label={t('label.unsubscribeOption1')}
        />
        <FormControlLabel
          value={t('label.unsubscribeOption2')}
          control={<Radio />}
          label={t('label.unsubscribeOption2')}
        />
        <FormControlLabel
          value={t('label.unsubscribeOption3')}
          control={<Radio />}
          label={t('label.unsubscribeOption3')}
        />
        <FormControlLabel
          value={t('label.unsubscribeOption4')}
          control={<Radio />}
          label={t('label.unsubscribeOption4')}
        />
        <FormControlLabel
          value="other"
          control={<Radio />}
          label={t('label.unsubscribeOption5')}
        />
        {selectOther === 'other' && (
          <TextField
            label="Other"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          />
        )}
      </RadioGroup>
    );
  };

  const handleSubmit = () => {
    const data = {
      email: email,
      content: selectedValue,
      country
    };
    dispatch(unsubscribe(data));
  };

  const renderMain = () => (
    <Box className="main">
      <Box className="container" sx={{ mx: 'auto', mt: { md: '5%' } }}>
        <Box
          component="section"
          className="hero-contact"
          sx={{
            background: `url('${unsubscribeImage}') no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: '35% 70%',
            position: 'relative',
            width: '100%',
            height: '45vh',
          }}
        >
          <Box sx={heroTitleStyle}>
            <Typography
              variant="h1"
              sx={{ fontSize: 'inherit', fontFamily: 'inherit' }}
            >
              {t('title.unsubscribe')}
            </Typography>
          </Box>
        </Box>
        <Box
          component="section"
          sx={{
            border: '1px solid',
            py: '50px',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormControl
            sx={{
              width: { xs: '80%', sm: '60%', md: '45%', lg: '40%', xl: '30%' },
              p: '25px',
              background: '#f9f9f9',
              boxShadow:
                '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)',
            }}
          >
            <Typography
              variant="h3"
              fontSize="30px"
              fontFamily='"Poppins", sans-serif'
              sx={{
                mb: '10px',
              }}
            >
              IDRApoll
            </Typography>
            <Typography
              variant="h4"
              fontFamily='"Poppins", sans-serif'
              fontSize="13px"
              sx={{ mb: '15px' }}
            >
              {t('label.unsubscribeText')}
            </Typography>
            <TextField
              placeholder="Email *"
              size="small"
              sx={{ my: '5px', background: 'white' }}
              onChange={(e) => setEmail(e.target.value)}
              required
            ></TextField>
            <Dropdown
              sx={{ mb: '6%' }}
              defaultValue="Albania"
              value={country}
              onChange={(e: any) => setCountry(e.target.value)}
              options={[
                { label: t('label.albania'), value: 'Albania' },
                { label: t('label.kosovo'), value: 'Kosovo' },
              ]}
            />


            {RadioButton()}
            <LoadingButton
              loading={isLoading}
              type="submit"
              sx={{
                textDecoration: 'none',
                background: '#da6f31',
                color: 'white',
                my: '10px',
                textTransform: 'capitalize',
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: '#ce7f17',
                  transition: 'background-color 0.3s ease-in-out',
                },
                span: {
                  color: 'white',
                  width: 20,
                  height: 20,
                },
              }}
              onClick={() => handleSubmit()}
            >
              {t('button.send')}
            </LoadingButton>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
  return <DefaultLayout content={renderMain()} />;
};

export default Unsubscribe;
