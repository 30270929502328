import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Images } from '@/Themes';
import { Box, Stack } from '@mui/material';
import DefaultLayout from '@/Components/DefaultLayout';

import {
  CustomersSection,
  // ForClientsSection,
  HeroSection,
  WhatIdraPollSection,
  ProductsSection,
  RegisterSection,
  ResultsSection,
  RewardsSection,
  StatisticalSection,
  WhyIdraPollSection,
} from './index';
import ScrollTopButton from '@/Components/Common/ScrollTopButton';

const Home: React.FC = () => {
  const renderMain = () => {
    return (
      <Box
        className="main-container"
        sx={{
          position: 'relative',
          body: {
            position: 'relative',
            fontFamily:
              'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important',
          },
        }}
      >
        <ScrollTopButton />
        <HeroSection />
        <WhatIdraPollSection />
        <RewardsSection />
        <RegisterSection />
        <ResultsSection />
        <Stack
          sx={{
            background: `url('${Images.b2b}')`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          {/* <ForClientsSection /> */}
          <ProductsSection />
        </Stack>
        <WhyIdraPollSection />
        <StatisticalSection />
        <CustomersSection />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>IDRApoll – anketa online në Shqipëri dhe Kosovë</title>
        <meta name="description" content="Merr pjesë në anketat më të fundit online në Shqipëri dhe Kosovë me IDRApoll. Fitoni shpërblime duke marrë pjesë në sondazhe të ndryshme." />
        <meta name="keywords" content="anketa online, sondazhe, Shqipëri, Kosovë, IDRApoll, shpërblime" />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="IDRApoll – anketa online në Shqipëri dhe Kosovë" />
        <meta property="og:description" content="Merr pjesë në anketat më të fundit online në Shqipëri dhe Kosovë me IDRApoll." />
        <meta property="og:image" content="/src/Assets/logo.svg" />
        <meta property="og:url" content="https://www.idrapoll.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="IDRApoll – anketa online në Shqipëri dhe Kosovë" />
        <meta name="twitter:description" content="Merr pjesë në anketat më të fundit online në Shqipëri dhe Kosovë me IDRApoll." />
        <meta name="twitter:image" content="/src/Assets/logo.svg" />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.idrapoll.com/" />
      </Helmet>
      <DefaultLayout content={renderMain()} />
    </>
  );
};

export default Home;
