import { ISideBarList } from '@/Interfaces/Menu.interface';
import { ROUTERS } from '@constants';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const USER_MENU: ISideBarList[] = [
  {
    label: 'whatIsIDRApoll',
    value: ROUTERS.HOME,
  },
  {
    label: 'rewards',
    value: ROUTERS.HOME,
  },
  {
    label: 'results',
    value: ROUTERS.HOME,
  },
];

const ADMIN_MENU: ISideBarList[] = [
  {
    label: 'dashboard',
    value: ROUTERS.ADMIN_DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    label: 'systemUser',
    value: ROUTERS.SYSTEM_USER,
    icon: <PersonIcon />,
  },
  {
    label: 'about',
    value: 'about',
    icon: <InfoIcon />,
    subOptions: [
      {
        label: 'whatIsIDRAPoll',
        value: ROUTERS.WHAT_IDRA_POLL,
      },
      {
        label: 'rewards',
        value: ROUTERS.REWARDS,
      },
      {
        label: 'productsAndServices',
        value: ROUTERS.PRODUCTS_AND_SERVICES,
      },
      {
        label: 'whyIDRApoll',
        value: ROUTERS.WHY_IDRA_POLL,
      },
      {
        label: 'statistical',
        value: ROUTERS.STATISTICAL,
      },
      {
        label: 'resultsFromIDRApoll',
        value: ROUTERS.RESULTS,
      },
      {
        label: 'customers',
        value: ROUTERS.CUSTOMERS,
      },
    ],
  },
  {
    label: 'Unsubscribe',
    value: ROUTERS.INBOX,
    icon: <InboxOutlinedIcon />,
  },
  // {
    // label: 'contact',
    // value: ROUTERS.CONTACT_MANAGEMENT,
    // icon: <ContactMailOutlinedIcon />,
  // },
  {
    label: 'upload',
    value: ROUTERS.UPLOAD_GALLERY,
    icon: <CloudUploadIcon />,
  },
  {
    label: 'logout',
    value: ROUTERS.AUTH,
    icon: <LogoutIcon />,
  },
];

export default {
  USER_MENU,
  ADMIN_MENU,
};
