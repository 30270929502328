import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import Root from './Routers';
import './Locale';
import { HelmetProvider } from 'react-helmet-async';

document.title = import.meta.env.VITE_APP_TITLE || '';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <Root />
  </HelmetProvider>
);
